import { useEffect, useState } from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import CompanyInformation from '../../components/ProfilePage/CompanyInformation';
import { ProductFilterProvider } from '../../contexts/ProductFilterContext';
import LegalRepresentative from '../../components/ProfilePage/LegalRepresentive';
import BankInformation from '../../components/ProfilePage/BankInformation';
import Security from '../../components/ProfilePage/Security';
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import config from './../../config';
import './styles.scss';
import { toast } from 'react-toastify';
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Profile() {
  const [value, setValue] = useState(0);
  const [countries, setCountries] = useState([]);
  const [screenSize, setScreenSize] = useState<any>();
  const [formSecurity, setFormSecurity] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  const [form, setForm] = useState({
    profileLogoURI: null,
    businessName: null,
    email: null,
    website: null,
    phoneNumber: null,
    businessLicensePhotoURI: null,
    taxNumber: null,
    currencyCode: null,
    languageCode: null,
    bank: {
      bankName: null,
      accountHolder: null,
      iban: null,
      bic: null

    },
    address: {
      line1: null,
      line2: null,
      city: null,
      province: null,
      postalCode: null,
      countryCode: null,
      countryName: null
    },
    legalRepresentative: {
      salutation: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      nationality: null,
      email: null,
      phone: null,
      personalIdPhotoURI: null,
      residenceCountry: null,
      address: {
        line1: null,
        line2: null,
        city: null,
        province: null,
        postalCode: null,
        countryCode: null,
        countryName: null
      }
    },
  })
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };



  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getAllCountry = () => {
    fetch('/api/v1/settings/values?property=Country-list')
      .then(res => res.json())
      .then(data => {
        setCountries(data.AllCountries || [])
      })
  }

  useEffect(() => {
    setScreenSize(getCurrentDimension());
    getProfile();
    getAllCountry();
  }, []);

  function getCurrentDimension() {
    let width = null;
    let height = null;
    if (typeof window !== "undefined") {
      width = window.innerWidth;
      height = window.innerHeight;
    }
    return {
      width,
      height,
    };
  }

  const handleChangeFormValue = (e: any) => {
    setForm(e)
  };

  const handleChangeFormSecurityValue = (e: any) => {
    setFormSecurity(e)
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);


  const getProfile = () => {
    axios.get(`${config.baseUrl}api/v1/profile`, {
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then(res => {
      setForm(res.data.profile)
    })
  }

  const onSubmit = () => {
    axios.post(`${config.baseUrl}api/v1/profile`, form, {
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then(res => {
      // TODO
        toast.success("Profile updated.");
    }).catch(e => {
        toast.error("Failed to update profile.");
    })
  }

    const onSubmitSecurity = () => {
    axios.post(`${config.baseUrl}api/v1/account/changePassword?CurrentPassword=${formSecurity.currentPassword}&NewPassword=${formSecurity.newPassword}&ConfirmPassword=${formSecurity.confirmPassword}`, {
      headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        //Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }).then(res => {

    })
  }


  return (
    <ProductFilterProvider>
      <div className="container p-2 p-sm-3 p-md-4 add-product ">
        <div className="container sticky-header bg-white pt-2 pb-0 pb-md-1 mt-0">
          <div className="row">
            <div className="col-lg-3 col-12">
              <h2>Profile</h2>
            </div>
            <div className="col-lg-9 col-12"></div>
          </div>
        </div>

        {screenSize?.width > 576 ? (
          <div className="product-tab mt-3 p-3 p-md-1">

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Company Information" {...a11yProps(0)} />
                <Tab label="Legal representative" {...a11yProps(1)} />
                <Tab label="Bank information" {...a11yProps(2)} />
                <Tab label="Security" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CompanyInformation countries={countries} form={form} keyForm={'address'} handleChange={(e: any) => handleChangeFormValue(e)} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LegalRepresentative countries={countries} form={form} keyForm={'legalRepresentative'} handleChange={(e: any) => handleChangeFormValue(e)} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <BankInformation form={form} keyForm={'bank'} handleChange={(e: any) => handleChangeFormValue(e)} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Security formSecurity={formSecurity} handleChangeSecurity={(e: any) => handleChangeFormSecurityValue(e)} />
            </TabPanel>
          </div>
        )
          : (
            <div className="product-accrodin m-0 px-2">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Company Information
                </AccordionSummary>
                <AccordionDetails>
                  <CompanyInformation countries={countries} form={form} keyForm={'address'} handleChange={(e: any) => handleChangeFormValue(e)} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Legal representative
                </AccordionSummary>
                <AccordionDetails>
                  <LegalRepresentative countries={countries} form={form} keyForm={'legalRepresentative'} handleChange={(e: any) => handleChangeFormValue(e)} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Bank information
                </AccordionSummary>
                <AccordionDetails>
                  <BankInformation form={form} keyForm={'bank'} handleChange={(e: any) => handleChangeFormValue(e)} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Security
                </AccordionSummary>
                <AccordionDetails>
                  <Security formSecurity={formSecurity} handleChangeSecurity={(e: any) => handleChangeFormSecurityValue(e)} />
                </AccordionDetails>
              </Accordion>
            </div>
          )}

        <div className="pt-3 border-top w-100">
          <button className="float-right white-button" style={{ width: "147px" }}>
            Cancel
          </button>
          <button className="float-right black-button" onClick={() => {
            if (value === 3) {
              onSubmitSecurity()
            } else {
              onSubmit()
            }
          }} style={{ width: "147px", marginRight: '24px' }}>
            Save
          </button>
        </div>
      </div>
    </ProductFilterProvider>
  );


}
export default Profile;