import React, { createContext, ReactNode } from 'react';

// project import
import config from './../config';
import useLocalStorage from './../hooks/useLocalStorage';

// types
import { CustomizationProps, I18n, ThemeDirection } from './../types/config';

// initial state
const initialState: CustomizationProps = {
  ...config,
  onChangeLocalization: (lang: I18n) => { },
  onChangeDirection: (direction: ThemeDirection) => { },
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage('mantis-react-ts-config', initialState);

  const onChangeLocalization = (lang: I18n) => {
    setConfig({
      ...config,
      i18n: lang
    });
  };

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeLocalization,
        onChangeDirection,

      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
