import React from "react";

export default function Security({ handleChangeSecurity, formSecurity }: any) {



  const handleChangeValue = (e: any) => {
    handleChangeSecurity({
      ...formSecurity,
      [e.target.name]: e.target.value
    })
  }

  return (
    <table className="add-table no-border">
      <tbody>
        <tr className="no-border">
          <td>Current Password</td>
          <td>
            <input
              type="password"
              name="currentPassword"
              value={formSecurity.currentPassword || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>New Password</td>
          <td>
            <input
              type="password"
              name="newPassword"
              value={formSecurity.newPassword || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>Confirm New Password</td>
          <td>
            <input
              type="password"
              name="confirmPassword"
              value={formSecurity.confirmPassword || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter Password ..."
              aria-label="Enter Password ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}