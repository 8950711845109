import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link} from '@mui/material';

const Footer = () => (
    <footer className="container-fluid footer mt-5">
        <div className="row">
            <div className="col-12 col-sm-2">
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/Branding-w2w-dark.png'}
                        alt={""}
                    />
                </Link>
            </div>
            <div className="col-12 col-sm-8 center-column-footer">
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/Mastercard.png'}
                        alt={""}
                    />
                </Link>
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/PayPal.png'}
                        alt={""}
                    />
                </Link>
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/GooglePay.png'}
                        alt={""}
                    />
                </Link>
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/Visa.png'}
                        alt={""}
                    />
                </Link>
                <Link component={RouterLink} to="#" target="_blank" variant="caption" color="textPrimary">
                    <img
                        className="logo"
                        src={'/images/ApplePay.png'}
                        alt={""}
                    />
                </Link>
            </div>
            <div className="col-12 col-sm-2">
                <p>© 2023 watch2watch</p>
            </div>
        </div>
    </footer>
);

export default Footer;
