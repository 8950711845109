import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function LegalRepresentative({ form = {
    legalRepresentative: {}
}, keyForm, handleChange, countries }: any) {

    const handleChangeValue = (e: any) => {
        const _form = form[keyForm][e.target.name] || form[keyForm][e.target.name] === '' || form[keyForm][e.target.name] === null ? {
            ...form,
            [keyForm]: {
                ...form[keyForm],
                [e.target.name]: e.target.value
            }
        } : {
            ...form,
            ...form[keyForm],

            [e.target.name]: e.target.value
        }


        handleChange(_form)
    }


    console.log('keyForm', keyForm, form)
    return (
        <table className="add-table no-border">
            <tbody>
                <tr className="no-border">
                    <td>Full Name</td>
                    <td>
                        <div className="row">
                            <div className="col-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    value={form[keyForm]?.firstName || ''}
                                    onChange={(e) => handleChangeValue(e)}
                                    placeholder="Enter FirstName ..."
                                    aria-label="Enter FirstName ..."
                                    aria-describedby="basic-addon2"
                                />
                            </div>
                            <div className="col-6 ">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="lastName"
                                    value={form[keyForm]?.lastName || ''}
                                    onChange={(e) => handleChangeValue(e)}
                                    placeholder="Enter LastName ..."
                                    aria-label="Enter LastName ..."
                                    aria-describedby="basic-addon2"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Date of Birth</td>
                    <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={["DatePicker", "DatePicker", "DatePicker"]}
                            >
                                <DatePicker
                                    className="form-control"
                                    onChange={(e) => {
                                        let evt = { target: { name: 'dateOfBirth', value: dayjs(e) } }
                                        handleChangeValue(evt);
                                    }}
                                    value={dayjs(form[keyForm]?.dateOfBirth) || ''}
                                    slotProps={{ textField: { size: "small", name: 'dateOfBirth' } }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Email</td>
                    <td>
                        <input
                            type="email"
                            name="email"
                            value={form[keyForm]?.email || ''}
                            onChange={(e) => handleChangeValue(e)}
                            className="form-control"
                            placeholder="Enter Email ..."
                            aria-label="Enter Email ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Phone Number</td>
                    <td>
                        <input
                            type="string"
                            name="phone"
                            value={form[keyForm]?.phone || ''}
                            onChange={(e) => handleChangeValue(e)}
                            className="form-control"
                            placeholder="Enter Phone Number ..."
                            aria-label="Enter Phone Number ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>

                <tr className="no-border">
                    <td>Mobile Number</td>
                    <td>
                        <input
                            type="string"
                            name="mobile"
                            value={form[keyForm]?.mobile || ''}
                            onChange={(e) => handleChangeValue(e)}
                            className="form-control"
                            placeholder="Enter Mobile Number ..."
                            aria-label="Enter Mobile Number ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>

                <tr className="no-border">
                    <td>Nationality</td>
                    <td>
                        <input
                            type="string"
                            name="nationality"
                            value={form[keyForm]?.nationality || ''}
                            onChange={(e) => handleChangeValue(e)}
                            className="form-control"
                            placeholder="Enter Nationality ..."
                            aria-label="Enter Nationality ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Residence country</td>
                    <td>
                        <input
                            type="string"
                            name="residenceCountry"
                            value={form[keyForm]?.residenceCountry || ''}
                            onChange={(e) => handleChangeValue(e)}
                            className="form-control"
                            placeholder="Enter Residence Country ..."
                            aria-label="Enter Residence Country ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
