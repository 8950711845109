import axios from 'axios';
import config from '../config';

const http = axios.create({
    baseURL: config.baseUrl
})

// // Add a request interceptor
http.interceptors.request.use(function (config) {
    // Do something before request is sent

    console.log('config request', config);
    //config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    // {
    //     Accept: "application/json, text/plain, */*"
    //     Authorization: `Bearer ${localStorage.getItem('token')}`
    // }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
http.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('config response', response);

    //if (response.status === 302) {
    if (response.request.responseURL && response.request.responseURL.includes("api/v1/account/login")){
        window.location.href = '/account/login'
    }

    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});


// 
export default http