import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import UploaderProgress from "../Common/UploaderProgress";

export default function CompanyInformation({ form = {
    address: {}
}, keyForm, handleChange, countries }: any) {

    //TODO: Component for image upload + progress(without dragNdrop)
    const [progress, setProgress] = useState(0);
    const [progressLicense, setProgressLicense] = useState(0);

    const [currentFile, setCurrentFile] = useState<any>();
    const [currentFileLicense, setCurrentFileLicense] = useState<any>();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setCurrentFileLicense(form?.businessLicensePhotoURI);
        setCurrentFile(form?.profileLogoURI);
    }
        , [form]);

    const handleChangeValue = (e: any, useKeyForm: boolean) => {

        const _form =
            //TODO: @Mehdi, form[keyForm][e.target.name] || form[keyForm][e.target.name] === null 
            useKeyForm
                ? {
                    ...form,
                    [keyForm]: {
                        ...form[keyForm],
                        [e.target.name]: e.target.value
                    }
                } : {
                    ...form,
                    ...form[keyForm],
                    [e.target.name]: e.target.value
                }
        console.log('form[keyForm][e.target.name]', form)

        handleChange(_form)
    }

    const uploaderFiles = (event: any, type: string = '') => {
        setLoading(true);

        if (type) setProgressLicense(0);
        else setProgress(0);

        if (type) {
            setCurrentFileLicense(undefined)
        } else {
            setCurrentFile(undefined)

        }
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        let option = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent: any) => {
                if (type)
                    setProgressLicense(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                else
                    setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        };
        axios.post(`${config.baseUrl}api/v1/file/upload/photo`, formData, option).then((response) => {
            setLoading(false);
            if (response.status === 200) {
                if (type) {
                    setCurrentFileLicense(response?.data.replace('/', ''));
                    handleChange({
                        ...form,
                        businessLicensePhotoURI: response?.data.replace('/', '')
                    })
                } else {
                    setCurrentFile(response?.data.replace('/', ''));
                    handleChange({
                        ...form,
                        profileLogoURI: response?.data.replace('/', '')
                    })
                }
            } else {
                setMessage(" Upload Faild!")
            }
        }).catch((error) => {
            setLoading(false);
            setMessage(error?.response?.data)
        })
    }

    const handleChangeValueAutocomplete = (e: any, value: any) => {
        // console.log(e, value)
        handleChange({
            ...form,
            [keyForm]: {
                ...form[keyForm],
                countryCode: value?.Alpha2Code,
                countryName: value?.Name
            }
        })

    }

    const clearImage = (type: string = '') => {
        if (type) {
            setCurrentFileLicense('');
            handleChange({
                ...form,
                businessLicensePhotoURI: ''
            })
        } else {
            setCurrentFile('');
            handleChange({
                ...form,
                profileLogoURI: ''
            })
        }
    }

    const getCountryFromList = (countryCode: any) => {
        let result =
            countries
                ?
                countries.filter((item: any) => { return item?.Alpha2Code === countryCode; })
                : {};

        return result?.length === 1 ? result[0] : {};
    }


    return (
        <table className="add-table no-border">
            <tbody>
                <tr className="no-border ">
                    <td>Business logo</td>
                    <td className="profile-logo-td">
                        {
                            currentFile
                                ?
                                <img className="rounded-circle border" src={config.baseUrl + currentFile}
                                    width="104" height="104" alt="profile" />
                                :
                                (progress > 0 && progress < 100 ? <UploaderProgress progress={progress} /> : null)
                        }

                        <div className="company-information-logo">
                            <label htmlFor="file-uploader" className="white-button file-uploader" style={{ width: "147px", marginRight: '24px' }}>
                                <input id="file-uploader" type="file" onChange={(e) => uploaderFiles(e)} />
                                <AddIcon />
                                Upload
                            </label>
                            <button className="white-button" style={{ width: "147px" }} onClick={() => clearImage()}>
                                <DeleteOutlineIcon />
                                Delete
                            </button>
                        </div>

                    </td>
                </tr>
                <tr className="no-border">
                    <td>Business name</td>
                    <td>
                        <input
                            value={form.businessName || ''}
                            name="businessName"
                            type="text"
                            onChange={(e) => handleChangeValue(e, false)}
                            className="form-control disable-input"
                            placeholder="Enter Business name ..."
                            aria-label="Enter Business name ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Email</td>
                    <td>
                        <input
                            type="email"
                            value={form.email || ''}
                            onChange={(e) => handleChangeValue(e, false)}
                            name="email"
                            className="form-control"
                            placeholder="Enter Email ..."
                            aria-label="Enter Email ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Website</td>
                    <td>
                        <input
                            type="text"
                            value={form.website || ''}
                            name="website"
                            onChange={(e) => handleChangeValue(e, false)}
                            className="form-control"
                            placeholder="Enter Website ..."
                            aria-label="Enter Website ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Phone Number</td>
                    <td>
                        <input
                            type="string"
                            value={form.phoneNumber || ''}
                            name="phoneNumber"
                            onChange={(e) => handleChangeValue(e, false)}
                            className="form-control"
                            placeholder="Enter Phone Number ..."
                            aria-label="Enter Phone Number ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="border-bottom">
                    <td>Business license</td>
                    <td>
                        {
                            (progressLicense > 0 && progressLicense < 100 ? <UploaderProgress progress={progressLicense} /> : null)
                        }
                        <label htmlFor="file-uploader1" className="white-button file-uploader" style={{ width: "147px", marginRight: '24px' }}>
                            <input id="file-uploader1" type="file" onChange={(e) => uploaderFiles(e, 'license')} />
                            <AddIcon />
                            Upload
                        </label>
                        {
                            currentFileLicense
                                ?
                                <div className="green-text pt-2">
                                    <CheckCircleIcon />
                                    Confirmed
                                </div>
                                :
                                null
                        }

                    </td>
                </tr>
                <tr className="no-border">
                    <td>Address line 1</td>
                    <td>
                        <input
                            type="text"
                            value={form[keyForm]?.line1 || ''}
                            name="line1"
                            onChange={(e) => handleChangeValue(e, true)}
                            className="form-control"
                            placeholder="Enter Street address ..."
                            aria-label="Enter Street address ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Address line 2</td>
                    <td>
                        <input
                            type="text"
                            value={form[keyForm]?.line2 || ''}
                            name="line2"
                            onChange={(e) => handleChangeValue(e, true)}
                            className="form-control"
                            placeholder="Enter Building No. ..."
                            aria-label="Enter Building No. ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Country</td>
                    <td>
                        <Autocomplete
                            multiple={false}
                            id="custom-input-demo"
                            options={countries || []}
                            value={getCountryFromList(form[keyForm]?.countryCode)}
                            onChange={(e, v) => handleChangeValueAutocomplete(e, v)}
                            getOptionLabel={(item: any) => item.Name || ''}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.Alpha2Code}>
                                        {option.Name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <div className="input-group" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        type="text"
                                        name="Name"
                                        className="form-control"
                                        placeholder="Enter Country ..."
                                        aria-label="Enter Case Diameter ..."
                                        aria-describedby="basic-addon2"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text" id="basic-addon2">
                                            <span className=" input-group-img"></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </td>
                </tr>
                <tr className="no-border">
                    <td>Town / City</td>
                    <td>
                        <input
                            type="text"
                            name="city"
                            value={form[keyForm]?.city || ''}
                            onChange={(e) => handleChangeValue(e, true)}
                            className="form-control"
                            placeholder="Enter Town / City ..."
                            aria-label="Enter Town / City ..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
                <tr className="no-border" >
                    <td>State & Zip code</td>
                    <td>
                        <div className="row">
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="province"
                                    value={form[keyForm]?.province || ''}
                                    onChange={(e) => handleChangeValue(e, true)}
                                    className="form-control"
                                    placeholder="Enter State ..."
                                    aria-label="Enter State ..."
                                    aria-describedby="basic-addon2"
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={form[keyForm]?.postalCode || ''}
                                    onChange={(e) => handleChangeValue(e, true)}
                                    className="form-control"
                                    placeholder="Enter Zip code ..."
                                    aria-label="Enter Zip code ..."
                                    aria-describedby="basic-addon2"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className="no-border">
                    <td>VAT ID</td>
                    <td>
                        <input
                            type="text"
                            value={form?.taxNumber || ''}
                            name="taxNumber"
                            onChange={(e) => handleChangeValue(e, false)}
                            className="form-control"
                            placeholder="Enter VAT ID..."
                            aria-label="Enter VAT ID..."
                            aria-describedby="basic-addon2"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
