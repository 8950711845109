import React, { useEffect, useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Link } from "react-router-dom";
import config from "../../../../config";
// import Dropdown from 'react-bootstrap/Dropdown';

import useConfig from "../../../../hooks/useConfig";
const NavbarRight = ({ lang }: any) => {
  // const router = useRouter();
  const [profileInfo, setProfileInfo] = useState({
    companyName: "",
    countryCode: "",
    currencyCode: "CZK",
    email: "",
    languageCode: "EN",
    logoURI: "",
  });

  const { i18n, onChangeLocalization } = useConfig();


  useEffect(() => {
    // var data = new FormData();
    // data.append("email", "max@mustermann.com");
    // data.append("password",  "WDcC7$A92*fc9");
    // data.append("__RequestVerificationToken", "CfDJ8PC31lNw0ZhKs6fPOnkzOWWsNdTYIj15sfiuJYmCyFQoIslVf1nIkhq0x1xLZ9uulhQGVVMjzGJPyZ7fdpxKZz4viDWooJ0BTy5IGYiRQTt4IGvP3OTPN2gxwrmXWljCoECryVCO7aIj-IQ7ZxdzDWE");
    // data.append("returnurl",  "/api/v1/listing?PageNumber=1&PageSize=16");
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Accept": "application/json",
    //     "type": "formData"
    //   },
    //   body: data,
    //   redirect: ""
    // };
   

    //   fetch(`${config.baseUrl}api/v1/account/login`, requestOptions).then((response) => console.log('response ' , response))
     //fetch(`${config.baseUrl}api/v1/dealer/profile/TR1JSCM`)
     fetch(`${config.baseUrl}api/v1/profile/info`)
      .then(
        (response) => response.json()
      ).then((data) => {
        if (data) {
          setProfileInfo(data);
          setLanguage(data.languageCode)
        }
      })
  }, [])
  const [language, setLanguage] = useState(lang);
  // useEffect(() => {
  //   let _lang = router.locale == "ar" ? "AR" : "EN";
  //   setLanguage(_lang);
  // }, [router.locale]);




    function handleLogout() {
        window.location.href = '/account/logout';
    // fetch(`https://dev1.watch2watch.eu/api/v1/dealer/profile/TR1JSCM`).then(
    //   (response) => {
    //     console.log("logout response is : ", response);
    //   }
    // );
  }

    function handleProfile() {
        window.location.href = '/profile';
  }

  const handleOnchange = (e: any) => {
      // console.log('e.target.value', e.target.value)
    setLanguage(e.target.value)
    onChangeLocalization(e.target.value);
  }

  console.log('language', language, profileInfo)
  return (
    <ul className="navbar-nav ">
      <li className="nav-item dropdown">
        <button className="btn bell btn-dark">
          <img src={"/icons/Vector.svg"} alt={""} />
        </button>
      </li>
      <li className="nav-item dropdown d-none d-lg-flex pr-1">
        <button
          className="btn btn-dark language-switcher dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={"/icons/Union.svg"} alt={"language"} />
          {language}
        </button>

        <ul className="dropdown-menu bg-white shadow" style={{ textAlign: language === 'AR' ? 'right' : 'left', minWidth: '250px' }}>
          <li className="px-3 py-1">
            <div className="form-group">
              <strong className="text-dark font-weight-bold">Language</strong>
              <select defaultValue={i18n} className="form-control bg-white text-dark my-2 px-3" onChange={(e) => handleOnchange(e)}>
                <option className="px-1 py-2" value={'en'}>
                  EN
                </option>
                <option className="px-1 py-2" value={'de'}>
                  DE
                </option>

              </select>
            </div>
          </li>
          {/* <li className="px-3 py-1">
            <div className="form-group">
              <strong className="text-dark font-weight-bold">Currency</strong>
              <select defaultValue={profileInfo.currencyCode} className="form-control bg-white text-dark my-2 px-3">
                <option value={'EUR'} className="px-1 py-2" >
                  EUR (&euro;)
                </option>
                <option value={'USD'} className="px-1 py-2" >

                  USD ($)
                </option>
                <option value={'CZK'} className="px-1 py-2" >

                  CZK ($)
                </option>
              </select>
            </div>
          </li> */}
        </ul>
      </li>
      <li className="nav-item dropdown">
        <button
          className="btn btn-dark avatar dropdown-toggle d-none d-lg-inline"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
           <span>{profileInfo.countryCode}</span>
          {profileInfo.companyName}
        </button>

        <button className="btn btn-dark avatar dropdown-toggle d-lg-none" data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={"/images/Union.png"} alt={""} />
        </button>


        <ul className="dropdown-menu bg-white shadow ">
          <li>
            <button className="dropdown-item text-center text-dark nav-drop-down-hover" 
              onClick={() => handleProfile()}>
              <PersonOutlineOutlinedIcon style={{ marginRight: "8px" }} />
              Profile
            </button>
          </li>
          <li>
            <button
              className="dropdown-item text-center text-dark nav-drop-down-hover"
              onClick={() => handleLogout()}
            >
              <ExitToAppOutlinedIcon style={{ marginRight: "8px" }} />
              {/*<Link className="d-block text-dark px-1 py-2" to="/account/logout">Logout</Link>*/}
              Logout
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default NavbarRight;
