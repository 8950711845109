import React from 'react';
import Navigation from './Navigation';

const Header = () => {


    return (
        <header>
            <Navigation />
        </header>
    );

}

export default Header