import { ComponentType, LazyExoticComponent, ReactElement, ReactNode, Suspense } from 'react';

// project import
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: LazyExoticComponent<() => ReactElement> | ComponentType<ReactNode> | any) => (props: any) =>
(
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
