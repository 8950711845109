import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function NavPages () {
    return <div className="d-flex ms-auto mobile-view navbar-nav tenp">
    <ul className="navbar-nav " style={{position: 'relative' , top: '0'  , left:'0'}}>
  <li className="nav-item dropdown d-none d-lg-flex pr-1">
    <button
      className="btn btn-dark dropdown-toggle"
      data-bs-toggle="dropdown"
      style={{borderRadius: '4px'}}
      aria-expanded="false"
    >
      Dashboard
      <ExpandMoreIcon style={{position: 'relative' , top: '2px' }} />
    </button>

    <ul className="dropdown-menu bg-white shadow" >
      <li className="px-3 py-1 text-left">
      <Link className="d-block text-dark px-1 py-2" to="/listing">Watches</Link>
      {/*<Link className="d-block text-dark px-1 py-2" to="/product-list">My Products</Link>*/}
      <Link className="d-block text-dark px-1 py-2" to="https://watch2watch.eu/">Landing</Link>
      {/*<Link className="d-block text-dark px-1 py-2" to="/">Else</Link>*/}

      </li>
      </ul>
      </li>
     
      <li className="nav-item dropdown d-none d-lg-flex pr-1">
    <button
      className="btn btn-dark dropdown-toggle"
      data-bs-toggle="dropdown"
      style={{borderRadius: '4px'}}
      aria-expanded="false"
    >
      Listing
      <ExpandMoreIcon style={{position: 'relative' , top: '2px' }} />

    </button>

    <ul className="dropdown-menu bg-white shadow" >
      <li className=" text-left px-3 py-1">
      {/*<Link className="d-block text-dark px-1 py-2" to="/listing">Listing</Link>*/}
      <Link className="d-block text-dark px-1 py-2" to="/product-list">My Products</Link>
      {/*<Link className="d-block text-dark px-1 py-2" to="/add-product">Add Product</Link>*/}
      {/*<Link className="d-block text-dark px-1 py-2" to="/auth/login">Login</Link>*/}
      {/*<Link className="d-block text-dark px-1 py-2" to="/api/v1/settings/system/reset">ResetDb</Link>*/}
      </li>
      </ul>
      </li>
    {/*  <li className="nav-item dropdown d-none d-lg-flex pr-1">*/}
    {/*<button className="btn btn-dark dropdown-toggle" style={{borderRadius: '4px'}} aria-expanded="false">*/}
    {/*  Advertisment*/}
    {/*</button>   */}
    {/*  </li>*/}
      <li className="nav-item dropdown d-none d-lg-flex pr-1">
    <button
      className="btn btn-dark dropdown-toggle"
      data-bs-toggle="dropdown"
      style={{borderRadius: '4px'}}
      aria-expanded="false"
    >
      Orders
      <ExpandMoreIcon style={{position: 'relative' , top: '2px' }} />

    </button>

    <ul className="dropdown-menu bg-white shadow" >
      <li className="text-left px-3 py-1">
      <Link className="d-block text-dark px-1 py-2" to="/purchase-list" >Purchase List</Link>
      {/*<Link className="d-block text-dark px-1 py-2" to="/" >Else</Link>*/}
      </li>
      </ul>
      </li>
      </ul>
    </div>
}