import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import { ToastContainer } from 'react-toastify';




// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {

  return (
    <div>
      <Header />

      <main>
        <Outlet />

      </main>

      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
    </div>
  );
};

export default MainLayout;
