import React, { useState, createContext, useEffect } from "react";
import config from './../config';



export const ProductFilter = createContext<any>({});
export interface ProductFilterInterface { }
export const ProductFilterProvider = ({ children }: any) => {
  const [filters, setFilters] = useState<any>({
    brand: [],
    model: [],
    price: [null, null],
    caseSize: [],
    genders: [],
    location: [],
    certificate: [],
    searchText: [],
    order: [],
  });
  const [filterItems, setFilterItems] = useState<any>({
    brands: [],
    locations: [],
    certificates: [],
    caseSize: [],
  })

  function modifyFilter(param: string, value: any) {
    let array = filters[param];
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    } else {
      array.push(value)
    }
    setFilters({ ...filters, [param]: array })
  }
  useEffect(() => {
    fetch(`${config.baseUrl}api/v1/settings/listing/filters`)
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          const b = response?.brands.map((item: any) => {
            return {
              name: item.name,
              models: item.models.map((k: any) => k.model)
            }

          })
          setFilterItems({
            locations: response?.locations,
            certificates: response?.certificates,
            genders: response?.genders,
            brands: b
          })
        }
      });
  }, [])

  return (
    <ProductFilter.Provider value={{ filters, modifyFilter, setFilters, filterItems }} >
      {children}
    </ProductFilter.Provider>
  );
}