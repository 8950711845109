interface IProgressInfo {
    progress: number;
};

export default function UploaderProgress(props: IProgressInfo) {
    return (
        <>
            <div className="progress-container">
                <div className="progress mb-3">
                    <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={props.progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: props.progress + "%" }}>
                        {props.progress}%
                    </div>
                </div>
            </div>
        </>
    );
}