// types
import { DefaultConfigProps } from './types/config';

export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

//const baseUrl = 'https://dev2.watch2watch.eu/';
const baseUrl = '/';

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  baseUrl,
  defaultPath: '/',
  i18n: 'en',
  themeDirection: 'ltr'
};

export default config;
