import { useState } from "react";

export default function BankInformation({ form = {
  bank: {}
}, keyForm, handleChange }: any) {


  const handleChangeValue = (e: any) => {
    handleChange({
      ...form,
      [keyForm]: {
      ...form[keyForm],
      [e.target.name]: e.target.value
    }})
  }

  return (
    <table className="add-table no-border">
      <tbody>
        <tr className="no-border">
          <td>Bank Name</td>
          <td>
            <input
              name="bankName"
              value={form[keyForm].bankName || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter Bank name ..."
              aria-label="Enter Bank name ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>Account holder</td>
          <td>
            <input
              name="accountHolder"
              value={form[keyForm].accountHolder || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter Account holder ..."
              aria-label="Enter Account holder ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>IBAN</td>
          <td>
            <input
              name="iban"
              value={form[keyForm].iban || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter IBAN ..."
              aria-label="Enter IBAN ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td>BIC</td>
          <td>
            <input
              name="bic"
              value={form[keyForm].bic || ''}
              onChange={(e) => handleChangeValue(e)}
              className="form-control"
              placeholder="Enter BIC ..."
              aria-label="Enter BIC ..."
              aria-describedby="basic-addon2"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
